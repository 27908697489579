import './App.css';

import Player from './Player/Player';

function App() {
  return (
    <Player />
  );
}

export default App;
